import { defineStore } from 'pinia'
import type { GasFeeInfo, ApiGasFeeInfo } from '@base/types/fee'
const host = 'https://api.etherscan.io/api'
// TODO: change this to PRO and change move it to env
const apiKey = 'BTG8XHEM6AFR5IHQ2BAX6PFHSBR57MS9QF'

/**
 * @notice To use the data of NFT, you need to use the useNFT function. The useNFT function retrieves the data
 */
export const useFeeStore = defineStore('fee', () => {
  const loading = ref(false)
  const fees = ref<GasFeeInfo | null>(null)

  let updatedAt = 0
  // TODO: set a common duration
  const updateDuration = 15 * 1000

  onWalletReady(() => {
    // data init
    actionRefreshFees()
  })

  if (getCurrentInstance()) {
    onMounted(() => {
      const intervalId = setInterval(() => {
        actionRefreshFees()
      }, updateDuration)

      return () => {
        clearInterval(intervalId)
      }
    })
  }

  const fetchGasFees = async () => {
    const res = await $fetch(
      host + `?module=gastracker&action=gasoracle&apikey=${apiKey}`,
    )
    return res as ApiGasFeeInfo
  }

  async function actionRefreshFees(force = false) {
    if (
      force ||
      (loading.value === false &&
        new Date().valueOf() - updatedAt >= updateDuration)
    ) {
      loading.value = true

      await fetchGasFees()
        .then((response) => {
          updatedAt = new Date().valueOf()
          const gasPrices = response.result

          const gasFeeInfo: GasFeeInfo = {
            averageGasPrice: gasPrices.SafeGasPrice,
            safeLowGasPrice: gasPrices.ProposeGasPrice,
            fastGasPrice: gasPrices.FastGasPrice,
          }

          fees.value = gasFeeInfo
        })
        .catch((err) => console.error(err))
        .finally(() => (loading.value = false))
    }
  }

  return {
    loading,
    fees,
    actionRefreshFees,
  }
})
