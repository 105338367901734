import { ChainId } from 'deorderbook-sdk'

export default function useNetwork() {
  const { chainId, chains } = useChains()

  const explorerLink = computed(() => {
    if (!chainId.value) return ''
    return chains.value.find((chain) => chain.chainId === chainId.value)
      ?.blockExplorerUrls
  })

  const isTestnet = computed(() => chainId.value !== ChainId.ETH_MAINNET)

  const isEthernet = computed(() => {
    return (
      chainId.value !== ChainId.SCROLL_SEPOLIA
      // TODO: when support SCROLL network, revert this code
      // && chainId.value !== ChainId.SCROLL
    )
  })
  return { chainId, explorerLink, isTestnet, isEthernet }
}
